<template>
  <div class="flex justify-center items-center w-full h-screen">
    <section class="p-8 w-6/12 2xl:w-1/3 bg-white">
      <div class="w-full">
        <img class="mx-auto" src="@/assets/icons/mm-icon.svg" alt="MM icon">
      </div>
      <p class="mt-10 mb-5 2xl:mt-16 2xl:mb-8">Our team will contact you soon</p>
      <form @submit.prevent="submitMessageContact">
        <div class="w-full form__div">
          <input class="form__input" type="text" id="name" placeholder=" " v-model="contact.name" />
          <label for="name" class="form__label">Name</label>
        </div>
        <div class="w-full form__div">
          <input class="form__input" type="text" id="email" placeholder=" " v-model="contact.email" />
          <label for="email" class="form__label">Email</label>
        </div>
        <div class="w-full form__div">
          <input class="form__input" type="text" id="tel" placeholder=" " v-model="contact.tel" />
          <label for="tel" class="form__label">Tel</label>
        </div>
        <div class="w-full h-44 2xl:h-52 form__div">
          <textarea class="form__input" type="email" id="message" placeholder=" " v-model="contact.message" />
          <label for="message" class="form__label">Message (Optional)</label>
        </div>
        <Alert :alert="alert"/>
        <button class="w-full text-color mt-3 font-bold p-3 border-0 auth-color">Send</button>
      </form>
    </section>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { expressions } from "@/components/general/utils"
import Alert from "@/components/general/Alert"
const alert = ref({ message: '', active: false, error: false });

const contact = ref({
  name: '',
  email: '',
  tel: '',
  message: ''
})

async function submitMessageContact() {
  const { name, email, tel } = contact.value;
  if (!expressions.letters.test(name.trim())) {
    handleAlert('The name is a text only field!', true);
    return
  }

  if (!expressions.email.test(email.trim())) {
    handleAlert('Invalid Email', true);
    return
  }

  if (!expressions.telephone.test(tel.trim())) {
    handleAlert('Min 7 digits in the telephone!', true);
    return
  }
  // TODO: Functionality to send support contact.
  handleAlert('Your message has been sent to support!', false);
}

function handleAlert(message, error) {
  alert.value = { message, active: true, error }
  setTimeout(() => {
    alert.value.active = false;
  }, 3000);
}
</script>